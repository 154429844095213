import Vue from 'vue';
import { get as _get } from 'lodash-es';
import router from '@/router/index';
import store from '@/store';
import { getAccountIdFromCookie } from '@/util';
import CREATE_CAMPAIGN from '@/graphql/CreateCampaign.gql';
import GET_MASTER_THEME from '@/graphql/GetMasterTheme.gql';
import COPY_CAMPAIGN_FROM_ANOTHER_ACCOUNT from '@/graphql/CopyCampaignFromAnotherAccount.gql';
import { lsRetrieve, lsStore, lsRemove } from '@/helpers/localStorage';
import { CAMPAIGN_SHARE_LS_KEY, LIBRARY_STORE_KEY, WIZARD_SETUP_KEY } from '@/config/constants';
import { apolloClient } from '@/apollo';
import { getPaletteColors } from '@om/template-properties/src/getPaletteColors';
import { deleteOldBrandColorsLocalStorage } from '@/helpers/themeColorsLs';
import { guessLanguage } from '@/utils/lang';
import i18n from '@/i18n';
import { showUnfinishedOnboarding } from './onboarding-utils';

async function createCampaignAndRedirectToEditor(source = 'library', extraInput = {}) {
  const tplData = lsRetrieve(LIBRARY_STORE_KEY);

  if (tplData) {
    lsRemove(LIBRARY_STORE_KEY);
    const { templateId, color: baseColor, theme = undefined } = tplData;
    const input = {
      name: '',
      domainId: null,
      templateId,
      source,
      ...extraInput,
    };
    if (theme) {
      try {
        const { data } = await apolloClient.query({
          query: GET_MASTER_THEME,
          variables: { themeKitName: theme },
        });

        const themeKitId = data?.getMasterTheme?.themeKit?.id;
        if (themeKitId) {
          input.theme = themeKitId;
        }
      } catch (e) {
        console.error('Error while trying to query custom theme', e);
      }
    }
    if (baseColor)
      input.colors = getPaletteColors(!baseColor.includes('#') ? `#${baseColor}` : baseColor);
    try {
      const r = await apolloClient.mutate({
        mutation: CREATE_CAMPAIGN,
        variables: { input },
      });

      const campaignId = _get(r, 'data.createCampaign.id');
      const variantId = _get(r, 'data.createCampaign.variants.0._id');

      if (!campaignId || !variantId) {
        throw new Error('Failed to create campaign');
      }

      lsStore('set-domain', campaignId, false);

      window.location = `/${getAccountIdFromCookie()}/variant/${campaignId}/${variantId}/edit/new/?source=${source}`;
    } catch (e) {
      console.error(e);
      return false;
    }

    return true;
  }

  return false;
}

async function copyCampaignAndRedirectToEditor() {
  const token = lsRetrieve(CAMPAIGN_SHARE_LS_KEY);

  if (token) {
    lsRemove(CAMPAIGN_SHARE_LS_KEY);

    try {
      const result = await apolloClient.mutate({
        mutation: COPY_CAMPAIGN_FROM_ANOTHER_ACCOUNT,
        variables: { token },
      });

      const campaignId = _get(result, 'data.copyCampaignFromAnotherAccount.campaignId');
      const variantId = _get(result, 'data.copyCampaignFromAnotherAccount.variantId');

      if (!campaignId || !variantId) {
        throw new Error('Failed to copy campaign from another account');
      }

      lsStore('set-domain', campaignId, false);

      window.location = `/${getAccountIdFromCookie()}/variant/${campaignId}/${variantId}/edit/new/?source=share`;
    } catch (e) {
      console.error(e);
      return false;
    }

    return true;
  }

  return false;
}

async function showInitialScreenByUserType(type) {
  await store.dispatch('fetchAccount');
  deleteOldBrandColorsLocalStorage();
  if (await showUnfinishedOnboarding()) return;
  const isCreate = await createCampaignAndRedirectToEditor();
  const isCopy = await copyCampaignAndRedirectToEditor();
  if (isCreate || isCopy) return;

  const params = { userId: getAccountIdFromCookie() };

  const name = type === 'agency' ? 'agency_dashboard' : 'dashboard';
  router.replace({ name, params });
}

const trackPageView = (routerApp) => {
  routerApp?.$nextTick().then(() => {
    if (store.getters.isLoggedIn()) {
      store.dispatch('trackPageview');
    }
  });
};

let changeEmailNotified = false;
const shopifyInstallNotified = false;

const showEmailConfirmNotification = (to) => {
  const { confirm_email: confirmEmail } = to.query;

  if (confirmEmail && !changeEmailNotified) {
    const userLang = guessLanguage(navigator, to, store);

    changeEmailNotified = true;
    Vue.notify({
      type: 'success',
      text: i18n.t('changeEmail.successChange', userLang, { email: confirmEmail }),
      duration: 10000,
    });
  }
};

const showShopifyInstallNotified = (to) => {
  const { confirm_shopify_install: confirmShopifyInstall, domain } = to.query;

  const userLang = guessLanguage(navigator, to, store);

  if (confirmShopifyInstall && domain && !shopifyInstallNotified) {
    const msg = confirmShopifyInstall === 'success' ? 'success' : 'failed';
    Vue.notify({
      type: 'success',
      text: i18n.t(`connectShopify.${msg}`, userLang, { domain }),
      duration: 10000,
    });
  }
};

const isEditor = (source) => {
  const editorPathNames = [
    'campaign_settings',
    'editor',
    'variant_edit',
    'template_editor',
    'dc_goal_create',
  ];
  return editorPathNames.indexOf(source.name) > -1;
};

const afterShopifyInstall = (to) => to.name === 'login' || to.path === '/connect-shopify';

const getUTMFields = (to) => {
  const utmFields = store.getters.getUTMParams;
  if (!afterShopifyInstall(to) && utmFields) {
    const { timestamp: utmSavedAt, utmParams } = utmFields;
    if (Date.now() <= utmSavedAt + 10000) {
      return utmParams;
    }
    return null;
  }
};

const setUTMFields = (to) => {
  if (afterShopifyInstall(to)) {
    // eslint-disable-next-line camelcase
    const { utm_content, utm_medium, utm_source, utm_campaign, utm_term } = to.query;
    const utmParams = { utm_content, utm_medium, utm_source, utm_campaign, utm_term };
    if (Object.values(utmParams).some(Boolean)) {
      store.commit('setUTMParams', {
        utmParams,
        timestamp: Date.now(),
      });
    }
  }
};

const persistUTMParams = (to) => {
  let query = to.query;

  setUTMFields(to);

  const utmParams = getUTMFields(to);

  if (utmParams) {
    query = {
      ...query,
      ...utmParams,
    };
  }

  // eslint-disable-next-line camelcase
  const { utm_content, utm_medium, utm_source, utm_campaign, utm_term } = to.query;
  const utmFields = { utm_content, utm_medium, utm_source, utm_campaign, utm_term };

  if (Object.values(utmFields).some(Boolean) || Object.keys(query).length === 0 || !utmParams) {
    return null;
  }
  return { ...to, query };
};

const verifyOnboarding = (_, from, next) => {
  // navigates the user to the dashboard or the templates page based on the onboarding status.
  if (window.location.href.includes('optimonk.dev')) return next();
  const { onboardingFinished } = store.getters;
  if (onboardingFinished) {
    next(from);
    return;
  }
  next();
};

const addWizardAbTestVersion = (to, next) => {
  const wizardAbTestVersion = store.getters.userProfileWizardAbTest;
  if (to.query.abTest !== wizardAbTestVersion) {
    to.query.abTest = wizardAbTestVersion;
    next(to);
  }
  next();
};

const hasPermission = (to) => {
  if (to.matched.length && to.meta.permission.includes(store.state.accountType)) {
    if (
      to.meta.partnerPermission &&
      store.state.account?.partnerType &&
      !to.meta.partnerPermission.includes(store.state.account.partnerType)
    ) {
      return false;
    }
    const isAnyImpersonateHidden =
      store.state.impersonate && to.meta.denied?.includes('any-shared');
    const isSimpleImpersonateHidden =
      store.state.impersonate &&
      !store.state.affiliateImpersonate && // agency account management is an exception
      to.meta.denied?.includes('simple-shared');

    const isAffiliate =
      (to.meta.isAffiliate === true && !store.getters.isAffiliate) ||
      (to.meta.isAffiliate === false && store.getters.isAffiliate);

    const hasRole = to.meta.roles?.length && !to.meta.roles.includes(store.getters.userRole);

    const hasFeature =
      to.meta.features?.length &&
      to.meta.features.some((feature) => !store.getters.hasAccountFeature(feature));

    const hasShopify = to.meta.hasShopify === true && !store.getters.hasShopify;
    const hasShopifyOrShopRenter =
      to.meta.hasShopifyOrShopRenter === true &&
      !store.getters.hasShopify &&
      !store.getters.hasShopRenter;

    const results = [
      isAnyImpersonateHidden,
      isSimpleImpersonateHidden,
      isAffiliate,
      hasRole,
      hasFeature,
      hasShopify,
      hasShopifyOrShopRenter,
    ];
    return !results.some((result) => result === true);
  }
  return false;
};

const initializeEditor = (from, to) => {
  const html = document.querySelector('html');
  if (isEditor(to)) {
    store.commit('setStateAttr', { attr: 'isEditor', value: true });
    if (!['campaign_settings', 'dc_goal_create'].includes(to.name)) {
      document.body.className = 'brand-editor';
      html.className = 'brand-editor';
      html.setAttribute('lang', window.om.i18n.locale);
    } else {
      document.body.className = 'overflow-visible';
      html.className = '';
      html.removeAttribute('lang');
    }
  } else {
    store.commit('setStateAttr', { attr: 'isEditor', value: false });
    document.body.className = '';
    html.className = '';
    html.removeAttribute('lang');
  }

  if (isEditor(from) && !isEditor(to)) {
    store.commit('resetState', 'editor');
  }
};

const getLang = () => {
  const userLang = (navigator.language || navigator.userLanguage).split('-')[0];
  const lang = window.localStorage.locale || userLang;
  return lang;
};

export {
  createCampaignAndRedirectToEditor,
  copyCampaignAndRedirectToEditor,
  showInitialScreenByUserType,
  trackPageView,
  showEmailConfirmNotification,
  showShopifyInstallNotified,
  persistUTMParams,
  hasPermission,
  initializeEditor,
  getLang,
  verifyOnboarding,
  addWizardAbTestVersion,
};
