export const WIZARD_NAME = 'smart-wizard-brandkit';

export const LS_WIZARD_PATH = (databaseId) => `wizard-saved-path-${databaseId}`;

export const multiColorThemes = [
  'Essential',
  'Black',
  'Pure',
  'Inverse',
  'Midnight',
  'Groundy',
  'Edge',
  'Monochrome',
  'Botanic',
  'Spark',
  'Photographic',
  'Modern',
];

export const ORIGINAL_FLOW = 'A';
export const NON_ORIGINAL_FLOW = 'B';
